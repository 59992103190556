/* requires:
polyfill.js
*/

// display the current year in the footer
document.querySelector('.footer-year').innerHTML = new Date().getFullYear();

// Modified https://coolcssanimation.com/how-to-trigger-a-css-animation-on-scroll/
function scrollSpy(selector, animationClass, delay) {
  const animationObject = document.querySelectorAll(selector);

  for (let element of animationObject) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (delay !== undefined) {
              setTimeout(function () {
                element.classList.add(animationClass);
              }, delay);
            } else {
              element.classList.add(animationClass);
            }
            return;
          }
          // We're not intersecting, so remove the class! This enables the repeat animation.
          // element.classList.remove(animationClass);
        });
      },
      {
        rootMargin: '-100px',
      }
    );
    observer.observe(element);
  }
}

function parallax(selector, Yspeed = 0.05, Xspeed = 0) {
  const squares = document.querySelectorAll(selector);
  window.addEventListener('scroll', () => {
    const scrollPosition = window.scrollY;

    squares.forEach((square) => {
      const translateY = scrollPosition * Yspeed;
      const translateX = scrollPosition * Xspeed;
      square.style.transform = `translate(${translateX}px, ${translateY}px)`;
    });
  });
}

const searchIcon = document.querySelector('.nav-search-icon');
const searchBar = document.getElementById('search');
// Toggle visibility
window.addEventListener('click', (e) => {
  if (e.target.classList.contains('nav-search-icon')) {
    if (!searchBar.classList.contains('fade-in-quick')) {
      searchBar.style.display = 'block';
      searchBar.classList.remove('fade-out-quick');
      searchBar.classList.add('fade-in-quick');
      document.querySelector('.nav-search-bar').focus();
      // Add a click event listener to the document
      document.addEventListener('click', handleClickOutside);
    } else {
      searchBar.classList.remove('fade-in-quick');
      searchBar.classList.add('fade-out-quick');
      setTimeout(() => {
        searchBar.style.display = 'none';
      }, 200);
      // Remove the click event listener when search is hidden
      document.removeEventListener('click', handleClickOutside);
    }
  }
});

function handleClickOutside(e) {
  // Check if the clicked element is not the searchBar element
  if (!e.target.classList.contains('addsearch') && !e.target.classList.contains('form-control')) {
    searchBar.classList.remove('fade-in-quick');
    searchBar.classList.add('fade-out-quick');
    setTimeout(() => {
      searchBar.style.display = 'none';
    }, 200);
  }
}
